<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" borderless header-size="lg">
             <template #header-actions>
                <ul v-if="ssDocActionsEnabled" class="nav config-actions">

                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button
                                automation_id="btn_ss_docs_dropdown_toggle"
                                class="btn btn-theme dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                :disabled="readOnly">Reset to default...
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button
                                        automation_id="btn_reset_unassigned_ss_docs"
                                        type="button"
                                        class="dropdown-item"
                                        @click="onResetUnassignedDocs">Unassigned Documents
                                    </button>
                                </li>
                                <li>
                                    <button
                                        automation_id="btn_reset_all_ss_docs"
                                        type="button"
                                        class="dropdown-item"
                                        @click="onResetAllDocs">All Documents
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <rq-section-expand-collapse-all :section-group="sectionGroupName" />
                    </li>
                    <li class="nav-item">
                        <RqButton
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onCancel"
                            :disabled="readOnly">CANCEL</RqButton>
                    </li>
                    <li class="nav-item">
                        <RqButton
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave"
                            :disabled="readOnly">SAVE</RqButton>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <rq-section-card :title="sectionTitles[0]" :section-group="sectionGroupName" collapsible>
                <div class="row">
                    <div class="col col-12 col-lg-6 checkbox-group label-lg">
                        <b-form-checkbox automation_id="chk_premium_Totals_Only_for_Non_BreakOuts" id="premium_Totals_Only_for_Non_BreakOuts" v-model="systemDefaults.premiumTotalsOnlyNonBreakOuts" :disabled="readOnly">Premium Totals only for Non-Break Outs</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_show_Premium_Splits_on_Settlement_Statements" id="show_Premium_Splits_on_Settlement_Statements" v-model="systemDefaults.showPremiumSplitsOnHUDCSS" :disabled="readOnly">Show Premium Splits on Settlement Statements</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_log_Changes_to_HUD_SS" id="log_Changes_to_HUD_SS" v-model="systemDefaults.logChangesToHUD" :disabled="readOnly">Log Changes to HUD and Commercial Settlement Statements</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_log_Changes_to_CDF_ALTA" id="log_Changes_to_CDF_ALTA" v-model="systemDefaults.logChangesToCDF" :disabled="readOnly">Log Changes to CDF and ALTA</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_auto_Calculate_Down_Payment" id="auto_Calculate_Down_Payment" v-model="systemDefaults.autoCalcDownPayment" :disabled="readOnly">Auto Calculate Down Payment</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_split_Real_Estate_Agent_Commissions" id="split_Real_Estate_Agent_Commissions" v-model="systemDefaults.hudSplitRealtorCommissions" :disabled="readOnly">Split Real Estate Agent Commissions</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-lg-6 checkbox-group label-lg">
                        <b-form-checkbox automation_id="chkauto_Calculate_HUD_Line_901_Per_deim" id="auto_Calculate_HUD_Line_901_Per_deim" v-model="systemDefaults.hudAutocalcPerdiem" :disabled="readOnly">Auto Calculate Per Diem</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_transfer_MIP_Amount_from_Prepaids_to_Escrow" id="transfer_MIP_Amount_from_Prepaids_to_Escrow" v-model="systemDefaults.transferMipAmountToHud1000" :disabled="readOnly">Transfer MIP Amount from Prepaids to Escrow</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_transfer_Propert_Tax_Amount_from_Prepaids_to_Escrow" id="transfer_Propert_Tax_Amount_from_Prepaids_to_Escrow" v-model="cdfSystemDefaults.transProTaxFromPrepaidsToEscrows" :disabled="readOnly">Transfer Property Tax Amount from Prepaids to Escrow</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_prompt_for_CDF_Transaction_Without_Seller" id="prompt_for_CDF_Transaction_Without_Seller" v-model="cdfSystemDefaults.promptforCDFTransactionWithoutSeller" :disabled="readOnly">Prompt for CDF Transaction without Seller</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_always_Use_pg2ab_on_the_CDF" id="always_Use_pg2ab_on_the_CDF" v-model="cdfSystemDefaults.useTemplatePage2A" :disabled="readOnly">Always use Page 2a/2b on the CDF</b-form-checkbox>
                        <b-form-checkbox automation_id="chk_override_autocalculate_commission" id="override_autocalculate_commission" v-model="systemDefaults.overrideAutoCalcCommission" :disabled="readOnly">Override Auto Calculate Total Commission</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="splitAmt">Split Amount</label>
                        <rq-input-number
                            id="txt_splitAmt"
                            defaultValue="0"
                            decimals="2"
                            minValue="0"
                            prepend-text="$"
                            v-model="systemDefaults.hudSplitAmount"
                            input-group
                            :disabled="readOnly"
                        />
                    </div>
                </div>
            </rq-section-card>
            <rq-section-card :title="sectionTitles[1]" :section-group="sectionGroupName" collapsible>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="chrgOne">Charges 1</label>
                        <input
                            automation_id="txt_chrgOne"
                            type="text"
                            class="form-control"
                            placeholder="Charges 1"
                            maxlength="50"
                            v-model="systemDefaults.cssCharges1Description"
                            :disabled="readOnly"
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="chrgTwo">Charges 2</label>
                        <input
                            automation_id="txt_chrgTwo"
                            type="text"
                            class="form-control"
                            placeholder="Charges 2"
                            maxlength="50"
                            v-model="systemDefaults.cssCharges2Description"
                            :disabled="readOnly"
                        >
                    </div>
                    <div class="col col-12 col-lg-6 form-group pt-label label-lg" hidden>
                        <b-form-checkbox
                            automation_id="chk_csscharges2Hidden"
                            v-model="systemDefaults.cssCharges2Hidden"
                            :disabled="readOnly">Hide</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="chrgThree">Charges 3</label>
                        <input
                            automation_id="txt_chrgThree"
                            type="text"
                            class="form-control"
                            placeholder="Charges 3"
                            maxlength="50"
                            v-model="systemDefaults.cssCharges3Description"
                            :disabled="readOnly"
                        >
                    </div>
                    <div class="col col-12 col-lg-6 form-group pt-label label-lg" hidden>
                        <b-form-checkbox
                            automation_id="chk_csscharges3Hidden"
                            v-model="systemDefaults.cssCharges3Hidden"
                            :disabled="readOnly">Hide</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="chrgFour">Charges 4</label>
                        <input
                            automation_id="txt_chrgFour"
                            type="text"
                            class="form-control"
                            placeholder="Charges 4"
                            maxlength="50"
                            v-model="systemDefaults.cssCharges4Description"
                            :disabled="readOnly"
                        >
                    </div>
                    <div class="col col-12 col-lg-6 form-group pt-label label-lg" hidden>
                        <b-form-checkbox
                            automation_id="chk_csscharges4Hidden"
                            v-model="systemDefaults.cssCharges4Hidden"
                            :disabled="readOnly">Hide</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="chrgThree">Credits</label>
                        <input
                            automation_id="txt_credits"
                            type="text"
                            class="form-control"
                            placeholder="Credits"
                            maxlength="50"
                            v-model="systemDefaults.cssCreditsDescription"
                            :disabled="readOnly">
                    </div>
                    <div class="col col-12 col-lg-6 form-group pt-label label-lg" hidden>
                        <b-form-checkbox
                            automation_id="chk_csschargescreditsHidden"
                            v-model="systemDefaults.cssCreditsHidden"
                            :disabled="readOnly">Hide</b-form-checkbox>
                    </div>
                </div>
            </rq-section-card>
            <rq-section-card :title="sectionTitles[2]" :section-group="sectionGroupName" collapsible>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_borrowerHUDDocumentName">3-Page HUD-1 Borrower</label>
                        <rq-document-template-select
                            id="txt_borrowerHUDDocumentName"
                            placeholder="Select 3-Page HUD-1 Borrower..."
                            v-model:document-name="systemDefaults.borrowerHUDDocumentName"
                            v-model="systemDefaults.borrowerHUDDocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_sellerHUDDocumentName">3-Page HUD-1 Seller</label>
                        <rq-document-template-select
                            id="txt_sellerHUDDocumentName"
                            placeholder="Select 3-Page HUD-1 Seller..."
                            v-model:document-name="systemDefaults.sellerHUDDocumentName"
                            v-model="systemDefaults.sellerHUDDocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_hud1documentName">3-Page HUD-1</label>
                        <rq-document-template-select
                            id="txt_hud1documentName"
                            placeholder="Select 3-Page HUD-1..."
                            v-model:document-name="systemDefaults.huD1DocumentName"
                            v-model="systemDefaults.huD1DocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_hud1aDocumentName">3-Page HUD-1A</label>
                        <rq-document-template-select
                            id="txt_hud1aDocumentName"
                            placeholder="Select 3-Page HUD-1A..."
                            v-model:document-name="systemDefaults.huD1ADocumentName"
                            v-model="systemDefaults.huD1ADocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_hud1974_BorrowerDocumentName">2-Page HUD-1 Borrower</label>
                        <rq-document-template-select
                            id="txt_hud1974_BorrowerDocumentName"
                            placeholder="Select 2-Page HUD-1 Borrower..."
                            v-model:document-name="systemDefaults.huD1974_BorrowerDocumentName"
                            v-model="systemDefaults.huD1974_BorrowerDocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_hud1974_SellerDocumentName">2-Page HUD-1 Seller</label>
                        <rq-document-template-select
                            id="txt_hud1974_SellerDocumentName"
                            placeholder="Select 2-Page HUD-1 Seller..."
                            v-model:document-name="systemDefaults.huD1974_SellerDocumentName"
                            v-model="systemDefaults.huD1974_SellerDocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_hud1974_1DocumentName">2-Page HUD-1</label>
                        <rq-document-template-select
                            id="txt_hud1974_1DocumentName"
                            placeholder="Select 2-Page HUD-1..."
                            v-model:document-name="systemDefaults.huD1974_1DocumentName"
                            v-model="systemDefaults.huD1974_1DocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_hud1974_1ADocumentName">2-Page HUD-1A</label>
                        <rq-document-template-select
                            id="txt_hud1974_1ADocumentName"
                            placeholder="Select 2-Page HUD-1A..."
                            v-model:document-name="systemDefaults.huD1974_1ADocumentName"
                            v-model="systemDefaults.huD1974_1ADocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
            </rq-section-card>
            <rq-section-card :title="sectionTitles[3]" :section-group="sectionGroupName" collapsible>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_borrowerCSSDocumentName">Borrower Settlement Statement</label>
                        <rq-document-template-select
                            id="txt_borrowerCSSDocumentName"
                            placeholder="Select Borrower Settlement Statement..."
                            v-model:document-name="systemDefaults.borrowerCSSDocumentName"
                            v-model="systemDefaults.borrowerCSSDocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_purchaserCSSDocumentName">Purchaser Settlement Statement</label>
                        <rq-document-template-select
                            id="txt_purchaserCSSDocumentName"
                            placeholder="Select Purchaser Settlement Statement..."
                            v-model:document-name="systemDefaults.purchaserCSSDocumentName"
                            v-model="systemDefaults.purchaserCSSDocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="sellerCSSDocumentName">Seller Settlement Statement</label>
                        <rq-document-template-select
                            id="txt_sellerCSSDocumentName"
                            placeholder="Select Seller Settlement Statement..."
                            v-model:document-name="systemDefaults.sellerCSSDocumentName"
                            v-model="systemDefaults.sellerCSSDocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_purchaserSellerCSSDocumentName">Purchaser Seller Settlement Statement</label>
                        <rq-document-template-select
                            id="txt_purchaserSellerCSSDocumentName"
                            placeholder="Select Purchaser Seller Settlement Statement..."
                            v-model:document-name="systemDefaults.purchaserSellerCSSDocumentName"
                            v-model="systemDefaults.purchaserSellerCSSDocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
            </rq-section-card>
            <rq-section-card :title="sectionTitles[4]" :section-group="sectionGroupName" collapsible>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_borrowerCDFDocumentName">Borrower Closing Disclosure</label>
                        <rq-document-template-select
                            id="txt_borrowerCDFDocumentName"
                            placeholder="Select Borrower Closing Disclosure..."
                            v-model:document-name="cdfSystemDefaults.borrowerCDFDocumentName"
                            v-model="cdfSystemDefaults.borrowerCDFDocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="sellerCDFDocumentName">Seller Closing Disclosure</label>
                        <rq-document-template-select
                            id="txt_sellerCDFDocumentName"
                            placeholder="Select Seller Closing Disclosure..."
                            v-model:document-name="cdfSystemDefaults.sellerCDFDocumentName"
                            v-model="cdfSystemDefaults.sellerCDFDocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_cdfdocumentName">Closing Disclosure Form</label>
                        <rq-document-template-select
                            id="txt_cdfdocumentName"
                            placeholder="Select Closing Disclosure Form..."
                            v-model:document-name="cdfSystemDefaults.cdfDocumentName"
                            v-model="cdfSystemDefaults.cdfDocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_cdfdocumentName_P2ab">Closing Disclosure Form P2ab</label>
                        <rq-document-template-select
                            id="txt_cdfdocumentName_P2ab"
                            placeholder="Select Closing Disclosure Form P2ab..."
                            v-model:document-name="cdfSystemDefaults.cdfDocumentName_P2ab"
                            v-model="cdfSystemDefaults.cdfDocumentName_P2abID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_borrowerCDFDocumentName_P2ab">Borrower Closing Disclosure - P2AB</label>
                        <rq-document-template-select
                            id="txt_borrowerCDFDocumentName_P2ab"
                            placeholder="Select Borrower Closing Disclosure - P2AB..."
                            v-model:document-name="cdfSystemDefaults.borrowerCDFDocumentName_P2ab"
                            v-model="cdfSystemDefaults.borrowerCDFDocumentName_P2abID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_sellerCDFDocumentName_P2ab">Seller Closing Disclosure - P2AB</label>
                        <rq-document-template-select
                            id="txt_sellerCDFDocumentName_P2ab"
                            placeholder="Select Seller Closing Disclosure - P2AB..."
                            v-model:document-name="cdfSystemDefaults.sellerCDFDocumentName_P2ab"
                            v-model="cdfSystemDefaults.sellerCDFDocumentName_P2abID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_toscdfdocumentName">Closing Disclosure Without Seller</label>
                        <rq-document-template-select
                            id="txt_toscdfdocumentName"
                            placeholder="Select Closing Disclosure Without Seller..."
                            v-model:document-name="cdfSystemDefaults.toscdfDocumentName"
                            v-model="cdfSystemDefaults.toscdfDocumentNameID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_toscdfdocumentName_P2ab">Closing Disclosure Without Seller - P2AB</label>
                        <rq-document-template-select
                            id="txt_toscdfdocumentName_P2ab"
                            placeholder="Select Closing Disclosure Without Seller - P2AB..."
                            v-model:document-name="cdfSystemDefaults.toscdfDocumentName_P2ab"
                            v-model="cdfSystemDefaults.toscdfDocumentName_P2abID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
            </rq-section-card>
            <rq-section-card :title="sectionTitles[5]" :section-group="sectionGroupName" collapsible>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_altassborrowerDocument">Borrower ALTA Settlement Statement</label>
                        <rq-document-template-select
                            id="txt_altassborrowerDocument"
                            placeholder="Select Borrower ALTA Settlement Statement..."
                            v-model:document-name="cdfSystemDefaults.altassBorrowerDocument"
                            v-model="cdfSystemDefaults.altassBorrowerDocumentID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_altasssellerDocument">Seller ALTA Settlement Statement</label>
                        <rq-document-template-select
                            id="txt_altasssellerDocument"
                            placeholder="Select Seller ALTA Settlement Statement..."
                            v-model:document-name="cdfSystemDefaults.altassSellerDocument"
                            v-model="cdfSystemDefaults.altassSellerDocumentID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_alatasscombinedDocument">Combine ALTA Settlement Statement</label>
                        <rq-document-template-select
                            id="txt_alatasscombinedDocument"
                            placeholder="Select Combine ALTA Settlement Statement..."
                            v-model:document-name="cdfSystemDefaults.altassCombinedDocument"
                            v-model="cdfSystemDefaults.altassCombinedDocumentID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="txt_altasscashDocument">Cash ALTA Settlement Statement</label>
                        <rq-document-template-select
                            id="txt_altasscashDocument"
                            placeholder="Select Cash ALTA Settlement Statement..."
                            v-model:document-name="cdfSystemDefaults.altassCashDocument"
                            v-model="cdfSystemDefaults.altassCashDocumentID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
            </rq-section-card>
         </rq-page-section>
    </div>
</template>

<script>
    import { ref, computed } from "vue";
    import { mapState } from "vuex";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";
    import RqDocumentTemplateSelect from "@/shared/components/rq/RqDocumentTemplateSelect";
    import { useLicenseStore } from "@/store/modules/license";

    export default {
        name: "SettlementStatementSettingsForm",
        mixins: [SystemDefaultsFormMixin],
        components: { RqDocumentTemplateSelect },
        setup() {
            const sectionGroupName = ref("ss-settings-group");
            const sectionTitles = ref([
                "Options",
                "Commercial Tabs",
                "HUD Statement Documents",
                "Commercial Statement Documents",
                "Closing Disclosure Documents",
                "ALTA Settlement Statement Documents",
                "Closing Disclosure Form URL Settings"
            ]);
            const includeCdfDefaults = ref(true);
            const includeSettlementStatementDocuments = ref(true);

            const licenseStore = useLicenseStore();
            const ssDocActionsEnabled = computed(() => licenseStore.features.ssDocActions);
            return {
                sectionGroupName,
                sectionTitles,
                includeCdfDefaults,
                includeSettlementStatementDocuments,
                ssDocActionsEnabled
            };
        },
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            })
        },
        methods: {
            onResetAllDocs() { this.resetDocs(true); },
            onResetUnassignedDocs() { this.resetDocs(false); },
            async resetDocs(resetAll=false) {
                const self = this;
                try {
                    let apiPromise = self.$api.DocumentTemplatesApi.syncSsDocs(resetAll);
                    await self.$rqBusy.wait(apiPromise);
                    await this.fetchData();
                    let descriptor = resetAll ? "All" : "Unassigned";
                    self.$toast.success(`${descriptor} settlement documents reset successfully.`);
                }
                catch(error) {
                    console.error(error);
                    self.$toast.error("Settlement document reset failed.");
                }
            }
        }
    }
</script>
