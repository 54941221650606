<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" header-only borderless>
             <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-button
                            :automation_id="elementName('btn_add', this.itemTypeName)"
                            :ref="elementName('btn_add', this.itemTypeName)"
                            variant="theme"
                            @click="onAddItem"
                            :disabled=!canDoAnything
                            v-rq-tooltip.hover.top="{ title: `Add ${this.itemTypeName}` }"
                            >
                            Add
                        </b-button>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <div class="row ms-3 mt-1">
            <div class="col col-3 form-group form-required">
                <label for="dtp_effective_date">Effective Date</label>
                <rqdx-date-box
                    id="dtp_effective_date"
                    @valueChanged="onEffectiveDateChanged"
                    v-model="effectiveDate"
                    :show-clear-button="false"
                />
            </div>
        </div>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            :strikethrough-if-true="['isInActive']"
            target-inactive-column="isInActive"
            @rowDoubleClick="onEditItem"
            @activate="onActivateItem($event, 'Activate')"
            @inactivate="onActivateItem($event, 'Inactivate')"
            @edit="onEditItem"
            @copy="onCopyItem"
            @delete="onDeleteItem"
            @link="onLinkItem"
            @unlink="onUnlinkItem"
            @reset="onReset"
            integrated-search
            rq-filters
            show-include-inactive
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { RateDto, RateDataDto, RateDataShortDto, RateEffectiveDateDto }  from "../models";
    import { RateCreateType, RateEngineFileTypeName, TaxRateEffectiveBasis, EffectiveDateBasedOn, TaxType, TaxRateBasedOn }  from "../../enums";
    import { SystemLookupItem } from '@/shared/models/models';
    import PremiumFormAdd from "./PremiumFormAdd.vue";
    import PremiumLinkForm from "./PremiumLinkForm.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { RATE_MUTATIONS } from '@/store/mutations';
    import { RATE_ACTIONS } from '@/store/actions';
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

    export default {
        name:"PremiumsList",

        props: { },
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                verifiedItem: {},
                addEventName: "",
                effectiveDate: ""
            };
        },
        created(){
            this.effectiveDate = new Date();
            this.initGridConfig();
            this.initListeners();
            this.fetchData();
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                underwriters: state => state.system.lookups.underwriters
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "premiumNames"
            ]),
            accountingCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES); },
            effectiveDateBasedOn() { return EffectiveDateBasedOn.lookupItems; },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
            itemKey() { return "rateEffectiveDateID";},
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                const self = this;
                return [{ name: "edit", text: (!self.canDoAnything) ? "View":"Edit", eventName: "edit", requireSelection: true, tooltip: (!self.canDoAnything) ? `View ${this.itemTypeName}`: `Edit ${this.itemTypeName}`},
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, tooltip: `Delete ${this.itemTypeName}`, allowMultiSelection: true,
                            disabled: function(e) {
                                return !self.canDoAnything;
                            }},
                        { name: "copy", text: "Copy", eventName: "copy", requireSelection: true, tooltip: `Copy ${this.itemTypeName} to a new ${this.itemTypeName}`,
                            disabled: function(e) {
                                return !self.canDoAnything;
                            }},
                        { name: "link", text: "Link", eventName: "link", requireSelection: true, tooltip: `Link ${this.itemTypeName} to a new ${this.itemTypeName}`,
                            disabled: function(e) {
                                return !self.canDoAnything || ( !_.isNil(_.get(e.data, ['linkedPremiumRateID'])) && _.get(e.data, ['linkedPremiumRateID']) != 0);
                            }},
                         { name: "unlink", text: "Unlink", eventName: "unlink", requireSelection: true, tooltip: `Unlink ${this.itemTypeName}`,
                            disabled: function(e) {
                                return _.isNil(_.get(e.data, ['linkedPremiumRateID'])) || _.get(e.data, ['linkedPremiumRateID']) === 0;
                            }},
                        { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true,
                            disabled: function(e) {
                                return (!self.canDoAnything) ? true : !_.every(e.data, ['isInActive', true]); }
                            },
                        { name: "inactivate", text: "Inactivate", eventName: "inactivate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true,
                            disabled: function(e) {
                                   return (!self.canDoAnything) ? true : !_.every(e.data, ['isInActive', false]); }
                            }
                        ];
            },
            localSecurity() {
                 return this.securitySettings.findValues(["AllowAddEditRates"]);
            },
            canDoAnything(){
                return this.localSecurity.AllowAddEditRates;
            },
        },
        methods: {
            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.RatesApi.activate(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.toggleInactiveFlag(keys);
                        self.refresh();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            create(item){
                const self = this;
                let apiPromise = self.$api.RatesApi.create(item);
                return self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.onEditItem({data: {rateEffectiveDateID: data.rate.rateEffectiveDateID}});
                        self.$toast.success(`${self.itemTypeName} ${data.rate.code} was saved.`);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error("An error occurred while adding the entered premium rate.")
                        console.error(error);
                        return error;
                    });
            },

            link(item){
                const self = this;
                let apiPromise = self.$api.RatesApi.link(item);
                return self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.$toast.success(`${self.itemTypeName} was successfully linked.`);
                        self.fetchData();
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error("An error occurred while linking the selected premium rate.")
                        console.error(error);
                        return error;
                    });
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            onActivateItem(e, verb) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onAddItem() {
                this.clear();
                this.showAddRate(new RateDataShortDto({regionIDs: [this.user.regionID], effectiveDate: DateTimeHelper.nowClientStartOfDay(), rateCreateType: RateCreateType.NewRate}));
            },

            onCopyItem(e) {
                if(!e || !e.data) return;
                const self = this;
                self.clear();

                let apiPromise = self.$api.RatesApi.get(e.data.rateEffectiveDateID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let data = new RateDataDto(_.cloneDeep(result));
                        let rateDataShort = data.toShortObject()

                        self.showAddRate(new RateDataShortDto({...rateDataShort,
                                                            effectiveDate: DateTimeHelper.nowClientStartOfDay(),
                                                            rateCreateType: RateCreateType.CopyRate,
                                                            }));
                    })
            },

            onEditItem(e) {
                const self = this;
                if(!self.localSecurity) return ;

                self.clear();
                this.$router.push({ name: 'cfg:sscalc:premium', params: { rateEffectiveDateID: e.data.rateEffectiveDateID }});
            },

            onEffectiveDateChanged(e) {
                this.fetchData();
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let litems = _.isArray(e.data) ? e.data : [e.data];
                let linkMsg = `The Premium: ${litems[0].extendedDescription} is currently linked and cannot be deleted`;
                let useMsg = `The Premium: ${litems[0].extendedDescription} is being used on files and cannot be deleted.`;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(litems, "rateID");
                    let apiPromise = self.$api.RatesApi.delete(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(keys => {
                            self.removeGridItems(litems, self.itemKey);
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint - Linked") >= 0) {
                                 self.$dialog.confirm(`Delete Error`, linkMsg);
                            }else if (error.errorMessage.indexOf("REFERENCE constraint - In Use") >= 0) {
                                 self.$dialog.confirm(`Delete Error`, useMsg);

                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${litems.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onLinkItem(e) {
                if(!e || !e.data) return;
                this.showLinkRate(new RateDataShortDto({rateID: e.data.rateID, rateEffectiveDateID: e.data.rateEffectiveDateID,
                                                        effectiveDate: DateTimeHelper.nowClientStartOfDay(),
                                                        rateCreateType: RateCreateType.CopyRate }));
            },

            onUnlinkItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let ok = function () {
                    let item = new RateDataShortDto({rateID: e.data.rateID, effectiveDate: e.data.effectiveDate });
                    let apiPromise = self.$api.RatesApi.unlinkPremium(item);
                    return self.$rqBusy.wait(apiPromise)
                        .then(()=> {
                            self.$toast.success(`${self.itemTypeName} was successfully unlinked.`);
                            self.fetchData();
                            return true;
                        });
                }

                self.$dialog.confirm("Confirm Unlink", `Are you sure you want to Unlink the Premium?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onReset(e) {
                this.fetchData();
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "effectiveDate",
                            dataType: "date"
                        },
                        {
                            dataField: "extendedDescription",
                            caption: "Premium",
                            dataType: "string",
                            sortIndex: 0,
                            sortOrder: "asc"
                        },
                        {
                            dataField: "state",
                            dataType: "string",
                            lookup: {
                                dataSource: self.lookupHelpers.getStates(),
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: "countyList",
                            caption: "County",
                            dataType: "string",
                            width: 150
                        },
                        {
                            dataField: "underwriterIDs",
                            dataType: "string",
                            caption: "Underwriter",
                            width: 150,
                            calculateFilterExpression: (filterValue, operator) => {
                                if(_.isString(filterValue))
                                    return rowData => _.includes(_.toLower(rowData.underwriterList), _.toLower(filterValue));

                                if(operator !== "contains") return [];

                                if(!_.isArray(filterValue)) {
                                    return ["underwriterIDs", "contains", filterValue];
                                }

                                let filterExpr = [];
                                _.each(filterValue, (val, idx) => {
                                    if(idx > 0) filterExpr.push("or");
                                    filterExpr.push(...[
                                        ["underwriterIDs", "contains", val]
                                    ]);
                                });
                                return filterExpr;
                            },
                            rqFilter: {
                                valueExpr: "id",
                                displayExpr: "name",
                                dataSource: self.underwriters,
                                listOperator: "and",
                                valueOperator: "contains",
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let underwriters = self.underwriters;
                                if(_.isEmpty(cellInfo.value) || _.isEmpty(underwriters)) return;

                                let underwriterIDs =  _.split(cellInfo.value || "", ',');
                                let itemNames = _.sortBy(_.map(underwriterIDs, id =>
                                                        _.find(underwriters, r => _.parseInt(id) == r.id)?.name
                                                        ).filter(ele => ele != undefined));
                                let displayText = _.joinParts(itemNames, ", ");
                                let displayTitle = itemNames.length === 1
                                    ? displayText
                                    : `${itemNames.length} Underwriters Selected`;
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayTitle)
                                    .append(displayText)
                                    .appendTo(cellElement);
                            },
                        },
                        {
                            dataField: "rateEngineFileTypeName",
                            caption: "File Type",
                            dataType: "string",
                            visible: false,
                            lookup: {
                                dataSource: RateEngineFileTypeName.lookupItems,
                                displayExpr: "name",
                                valueExpr: "name"
                            },
                        },
                        {
                            dataField: "effectiveDateBasisID",
                            caption: "Effective Date Basis",
                            dataType: "number",
                            visible: false,
                            lookup: {
                                dataSource: EffectiveDateBasedOn.lookupItems,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: "linkedPremiumRateID",
                            caption: "Linked Premium",
                            dataType: "number",
                            lookup: {
                                dataSource: e => self.premiumNames,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: "code",
                            caption: "Premium Code",
                            dataType: "string",
                        },
                        {
                            dataField: "isInActive",
                            caption: "Inactive",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        {
                            dataField: "importDate",
                            caption: "Import Date",
                            dataType: "datetime"
                        },
                        {
                            dataField: "lastModifiedBy",
                            caption: "Modified By",
                            dataType: "string",
                        },
                        {
                            dataField: "lastModified",
                            caption: "Modified Date",
                            dataType: "datetime"
                        },
                        {
                            dataField: "rateRuleReference",
                            caption: "Rate Rule Reference",
                            dataType: "string",
                            visible: false
                        },
                        {
                            dataField: "loan_AccountingCode",
                            caption: "Loan Account Code",
                            dataType: "number",
                            visible: false ,
                            lookup: {
                                dataSource: self.accountingCodes,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: "owner_AccountingCode",
                            caption: "Owner Account Code",
                            dataType: "number",
                            visible: true,
                            lookup: {
                                dataSource: self.accountingCodes,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: "taxRateBasedOn",
                            caption: "Tax Rate Based On",
                            dataType: "number",
                            visible: false,
                            lookup: {
                                dataSource: TaxRateBasedOn.lookupItems,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: "taxRateEffectiveBasis",
                            caption: "Tax Rate Effective Date Basis",
                            dataType: "number",
                            visible: true,
                            lookup: {
                                dataSource: TaxRateEffectiveBasis.lookupItems,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: "taxType",
                            caption: "Tax Type",
                            dataType: "number",
                            visible: false,
                              lookup: {
                                dataSource: TaxType.lookupItems,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: "policyCode",
                            dataType: "string",
                            visible: false
                        },
                        {
                            dataField: "reportCode",
                            dataType: "string",
                            visible: false
                        },
                        {
                            dataField: "ricOverride",
                            caption: "RIC Override",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            visible: false
                        },
                        {
                            dataField: "filingCode",
                            dataType: "string",
                            visible: false
                        }
                    ],
                    wordWrapEnabled: false
                };

                self.gridDataSource = {
                    loadMode: "raw",
                    key: self.itemKey,
                    load: () => Promise.resolve(self.items)
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            copyItem(item) {
                const self = this;
                let apiPromise = self.$api.RatesApi.copy(item);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$router.push({ name: 'cfg:sscalc:premium', params: { rateEffectiveDateID: result.rateEffectiveDateID }});
                        return true;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error Copying ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            fetchData() {
                const self = this;
                let storePromise = self.$store.dispatch(RATE_ACTIONS.GET_RATES_BY_DATE, { effectiveDate: self.effectiveDate, forceRefresh: true })
                self.$rqBusy.wait(storePromise)
                    .then(result => {
                        self.items = _.map(result, i => new RateDto(i));
                        self.refresh();
                    });
            },

            clear() {
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            refresh() {
                this.clear();
                this.gridInstance.refresh();
            },

            removeGridItems(items, key) {
                _.pullAllBy(this.items, items, key);
            },

            showLinkRate(item){
                const self = this;
                self.originalData = item;
                self.$dialog.open({
                    title: `Link ${self.itemTypeName}`,
                    width: 700,
                    adaptive: true,
                    component: PremiumLinkForm,
                    props: {
                        modelValue: item.linkedPremiumRateID,
                        rateID: item.rateID
                    },
                    okTitle: "Save",
                    onOk: e => {
                        if (!e.component.isValid()) return false;
                        item.linkedPremiumRateID = e.component.linkedPremiumRateID;
                        return self.link(item);
                    }
                });

            },

            showAddRate(item) {
                const self = this;
                self.$dialog.open({
                    title: `Add ${self.itemTypeName}`,
                    width: 700,
                    adaptive: true,
                    component: PremiumFormAdd,
                    props: { item },
                    okTitle: "Save & Edit",
                    onOk: e => {
                        if (!e.component.isValid()) return false;
                        return self.create(item);
                    }
                });
            },

            toggleInactiveFlag(keys) {
                _.each(keys, k => {
                    let e = _.find(this.items, [this.itemKey, k]);
                    e.isInActive = !e.isInActive;
                });
            },
        }
    }
</script>
