<template>
    <div class="content-wrapper" v-if="hasEndorsement">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && hasValidationErrors"
        />
        <rq-page-section :title="editTitle" headerSize="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-button
                            automation_id="btn_cancel"
                            variant="action"
                            :disabled="readOnly"
                            @click="onCancel">Cancel
                        </b-button>
                    </li>
                    <li class="nav-item">
                        <b-button
                            automation_id="btn_save"
                            variant="action"
                            :disabled="readOnly"
                            @click="onSave({ userInitiated: true })">Save
                        </b-button>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <b-button automation_id="lnk_back" variant="link" :to="{ name:'cfg:sscalc:endorsementsandfees'}">View Endorsements &amp; Fees</b-button>
            </template>
        </rq-page-section>
        <RqTabs
            :tabs="tabItems"
            v-model="currentTabIndex">
            <template #tabs-end>
                <li class="nav-item ms-auto">
                    <rq-section-expand-collapse-all
                        v-if="currentTabIndex === 1 && data.endorsement.endorsementChargeTypeID === 5"
                        :section-group="sections.calculations.group"
                    />
                </li>
            </template>
             <template #details>
                <rq-page-section title="Details" headerSize="md" borderless>
                    <template #header-actions>
                        <ul class="nav ms-auto" >
                            <li class="nav-item form-group">
                                <b-form-checkbox
                                    automation_id="chk_always_apply_endorsement"
                                    id="chk_always_apply_endorsement"
                                    :disabled="readOnly"
                                    v-model="data.endorsement.alwaysApplyEndorsement"
                                    >Always Apply Endorsement</b-form-checkbox>
                            </li>
                            <li class="nav-item form-group">
                                <b-form-checkbox
                                    automation_id="chk_apply_to_title_services"
                                    id="chk_apply_to_title_services"
                                    :disabled="readOnly"
                                    v-model="data.endorsement.applyToTitleServices"
                                    >Apply To Title Services</b-form-checkbox>
                            </li>
                            <li class="nav-item form-group">
                                <b-form-checkbox
                                    automation_id="chk_inactive"
                                    id="chk_inactive"
                                    :disabled="readOnly"
                                    v-model="data.endorsement.isInactiveYN"
                                    >Inactive</b-form-checkbox>
                            </li>
                        </ul>
                    </template>
                    <fieldset :disabled="readOnly">
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.data.endorsement.code.$error } ">
                                <label for="txt_code">Code</label>
                                <input automation_id="txt_code" v-model="v$.data.endorsement.code.$model" type="text" class="form-control" placeholder="Code" maxlength="50">
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.data.endorsement.description.$error } ">
                                <label for="txt_description">Description</label>
                                <input automation_id="txt_description" v-model="v$.data.endorsement.description.$model" type="text" class="form-control" placeholder="Description">
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_filingCode">Filing Code</label>
                                <input automation_id="txt_filingCode" v-model="data.endorsement.filingCode" type="text" class="form-control" placeholder="Filing Code" maxlength="50">
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_policyCode">Policy Code</label>
                                <input automation_id="txt_policyCode" v-model="data.endorsement.policyCode" type="text" class="form-control" placeholder="Policy Code" maxlength="50">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.regionIDs.$error } ">
                                <label for="tb_regions">Region</label>
                                <dx-tag-box
                                    automation_id="tb_regions"
                                    ref="tb_regions"
                                    class="form-control"
                                    :data-source="regions"
                                    display-expr="displayName"
                                    value-expr="regionID"
                                    :show-selection-controls="true"
                                    :show-clear-button="true"
                                    :max-displayed-tags="1"
                                    :show-drop-down-button="true"
                                    apply-value-mode="useButtons"
                                    v-model="v$.regionIDs.$model"
                                    @valueChanged="onRegionChange"
                                    :search-enabled="true"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_state">State</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_state', id: 'drp_state' }"
                                    :items="usStates"
                                    value-expr="id"
                                    display-expr="id"
                                    v-model="data.endorsement.state"
                                    @change="onChangeState"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="tb_county">County</label>
                                <dx-tag-box
                                    automation_id="tb_counties"
                                    ref="tb_counties"
                                    class="form-control"
                                    :data-source="stateCounties"
                                    display-expr="name"
                                    value-expr="id"
                                    :show-selection-controls="true"
                                    :show-clear-button="true"
                                    :max-displayed-tags="1"
                                    :show-drop-down-button="true"
                                    :search-enabled="true"
                                    :disabled="!isStateSelected"
                                    apply-value-mode="useButtons"
                                    v-model:value="data.countyIDs"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_account_code">Account Code</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_account_code', id: 'drp_account_code' }"
                                    :items="accountingCodes"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="data.endorsement.accountingCodeID"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.data.premiumTypeIDs.$error } ">
                                <label for="tb_premium_types">File Service</label>
                                <dx-tag-box
                                    automation_id="tb_premium_types"
                                    ref="tb_premium_types"
                                    class="form-control"
                                    :data-source="orderServices"
                                    display-expr="name"
                                    value-expr="id"
                                    :show-selection-controls="true"
                                    :show-clear-button="true"
                                    :max-displayed-tags="1"
                                    :show-drop-down-button="true"
                                    :disabled="!isStateSelected"
                                    apply-value-mode="useButtons"
                                    v-model:value="data.premiumTypeIDs"
                                    :search-enabled="true"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="tb_order_category">File Category</label>
                                <dx-tag-box
                                    automation_id="tb_order_category"
                                    ref="tb_order_category"
                                    class="form-control"
                                    :data-source="orderCategories"
                                    display-expr="name"
                                    value-expr="id"
                                    :show-selection-controls="true"
                                    :show-clear-button="true"
                                    :max-displayed-tags="1"
                                    :show-drop-down-button="true"
                                    apply-value-mode="useButtons"
                                    v-model:value="data.orderCategoryIDs"
                                    :search-enabled="true"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="tb_underwriter">Underwriter</label>
                                <dx-tag-box
                                    automation_id="tb_underwriter"
                                    ref="tb_underwriter"
                                    class="form-control"
                                    :data-source="underwriters"
                                    display-expr="name"
                                    value-expr="id"
                                    :show-selection-controls="true"
                                    :show-clear-button="true"
                                    :max-displayed-tags="0"
                                    :show-drop-down-button="true"
                                    apply-value-mode="useButtons"
                                    v-model:value="data.underwriterIDs"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.data.endorsement.endorsementPolicyTypeID.$error } ">
                                <label for="drp_endorsement_policy_type">Endorsement Type</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_endorsement_policy_type', id: 'drp_endorsement_policy_type' }"
                                    :items="endorsementPolicyTypes"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="v$.data.endorsement.endorsementPolicyTypeID.$model"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_cdf_line_type">CDF Line Type</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_cdf_line_type', id: 'drp_cdf_line_type' }"
                                    :items="cdfLineTypes"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="data.endorsement.cdfLineType"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_hud_line_type">HUD Line Type</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_hud_line_type', id: 'drp_hud_line_type' }"
                                    :items="hudLineTypes"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="data.endorsement.hudLineType"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_show_outside_of_premiums">Show Outside of Premiums</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_show_outside_of_premiums', id: 'drp_show_outside_of_premiums' }"
                                    :items="endorsementYesNoOptions"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="data.endorsement.showOutsideOfPremiums"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_breakout_override">Break Out O/R</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_breakout_override', id: 'drp_breakout_override' }"
                                    :items="breakoutOptions"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="data.endorsement.breakoutOverride"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_rate_rule_reference">Rate Rule Reference</label>
                                <input automation_id="txt_rate_rule_reference" v-model="data.endorsement.rateRuleReference" type="text" class="form-control" placeholder="Rate Rule Reference" maxlength="50">
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_who_pays">Who Pays</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_who_pays', id: 'drp_who_pays' }"
                                    :items="whoPays"
                                    v-model="data.endorsement.whoPays"
                                    value-expr="id"
                                    display-expr="name"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="txt_pay_percent">Who Pays Percent</label>
                                <div class="input-group">
                                    <rqInputNumber
                                        v-model="data.endorsement.payPercent"
                                        defaultValue="0"
                                        decimals="3"
                                        minValue="0"
                                        maxValue="100"
                                        cssClass="form-control"
                                        automation_id="txt_pay_percent">
                                    </rqInputNumber>
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>

                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label>Payee</label>
                                <company-picker
                                    ref="refEndorsementPayee"
                                    automation_id="pic_endorsement_payee"
                                    dialogTitle="Select Payee"
                                    v-model="data.endorsement.payee">
                                </company-picker>
                            </div>

                        </div>
                        <div class="row" style="">

                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_underwriter_splits">Underwriter Split</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_underwriter_splits', id: 'drp_underwriter_splits' }"
                                    :items="underwriterSplitOptions"
                                    value-expr="id"
                                    display-expr="name"
                                    v-model="data.endorsement.underwriterSplitType"
                                    @valueChanged="onUnderwriterSplitChange"
                                />
                            </div>

                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                                <rq-action-form-group
                                    label="Override Underwriter Split %"
                                    labelFor="txt_split_override_percent"
                                >
                                    <template #default>
                                        <rqInputNumber
                                            id="txt_split_override_percent"
                                            automation_id="txt_split_override_percent"
                                            v-model="data.endorsement.splitOverridePCT"
                                            defaultValue="0"
                                            decimals="3"
                                            minValue="0"
                                            maxValue="100"
                                            cssClass="form-control"
                                            @blur="onUnderwriterSplitPctBlur"
                                            :disabled="enabledInputBox"
                                            input-group
                                            append-text="%"
                                        />
                                    </template>
                                    <!-- <template #below>
                                        <b-form-checkbox
                                            automation_id="chk_noUnderwriterSplit"
                                            id="noUnderwriterSplit"
                                            v-model="data.endorsement.noUnderwriterSplit"
                                            :disabled="false">No Underwriter Split
                                        </b-form-checkbox>
                                    </template> -->
                                </rq-action-form-group>

                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_tax_rate_effective_basis">Tax Rate Effective Basis</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_tax_rate_effective_basis', id: 'drp_tax_rate_effective_basis' }"
                                    :items="taxRateEffectiveBasisOptions"
                                    v-model="data.endorsement.taxRateEffectiveBasis"
                                    value-expr="id"
                                    display-expr="name"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_tax_rate_based_on">Tax Rate Based On</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_tax_rate_based_on', id: 'drp_tax_rate_based_on' }"
                                    :items="taxRateBasedOnOptions"
                                    v-model="data.endorsement.taxRateBasedOn"
                                    value-expr="id"
                                    display-expr="name"
                                />
                            </div>
                        </div>
                        <div class="row" style="">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_tax_contact_role">Tax Contact Role</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_tax_contact_role', id: 'drp_tax_contact_role' }"
                                    :items="taxContactRoles"
                                    v-model="data.endorsement.taxContactRole"
                                    value-expr="id"
                                    display-expr="name"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_tax_type">Tax Type</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_tax_type', id: 'drp_tax_type' }"
                                    :items="taxTypes"
                                    v-model="data.endorsement.taxType"
                                    value-expr="id"
                                    display-expr="name"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-auto ms-auto form-group mt-3">
                                Last Modified By: {{data.endorsement.lastModifiedBy}}<br/>Last Modified On: {{getDate("calcLastModified")}}
                                <!-- <label for="last_modified">Last Modified</label>
                                <div id="last_modified">By: {{data.endorsement.lastModifiedBy}}<br/>On: {{lastModified}}</div> -->
                            </div>
                        </div>
                    </fieldset>
                </rq-page-section>
             </template>

            <template #calculations >
                <rq-page-section title="Calculations" headerSize="md" borderless>
                    <fieldset :disabled="readOnly">
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_endorsement_based_on_id">Charge Method</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_endorsement_based_on_id', id: 'drp_endorsement_based_on_id' }"
                                    :items="endorsementChargeTypes"
                                    v-model="data.endorsement.endorsementChargeTypeID"
                                    value-expr="id"
                                    display-expr="name"
                                />
                            </div>
                        </div>
                        <!-- Flat Fee -->
                        <template v-if="data.endorsement.endorsementChargeTypeID == 1">
                            <div class="row">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_loan">Loan</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.endorsement.loan"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_loan">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_owner">Owner</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.endorsement.owner"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_owner">
                                        </rqInputNumber>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- Percentage -->
                        <template v-if="data.endorsement.endorsementChargeTypeID == 2">
                            <div class="row">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_percentage_percent">Percent</label>
                                    <div class="input-group">
                                        <rqInputNumber
                                            v-model="data.endorsement.percentagePercent"
                                            defaultValue="0"
                                            decimals="3"
                                            minValue="0"
                                            maxValue="100"
                                            cssClass="form-control"
                                            automation_id="txt_percentage_percent">
                                        </rqInputNumber>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_charge_percentage_basis_id">Percentage Basis</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_charge_percentage_basis_id', id: 'drp_charge_percentage_basis_id' }"
                                        :items="endorsementBasedOnOptions"
                                        v-model="data.endorsement.endorsementBasedOnID"
                                        value-expr="id"
                                        display-expr="name"
                                        :search-enabled="true"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-if="data.endorsement.endorsementBasedOnID == 7">
                                    <label for="txt_percentage_maximum">Premium</label>
                                    <premium-picker
                                        automation_id="drp_percentage_rate_id"
                                        dialog-title="Premium Rates"
                                        v-model="data.endorsement.percentageRateID"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_percentage_minimum">Min. Charge</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.endorsement.basedOnMinimum"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_percentage_minimum">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_percentage_maximum">Max. Charge</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.endorsement.basedOnMaximum"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_percentage_maximum">
                                        </rqInputNumber>
                                    </div>
                                </div>



                            </div>
                        </template>
                        <!-- BasedOnAmount -->
                        <template v-if="data.endorsement.endorsementChargeTypeID == 3">
                            <div class="row">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_based_on_amount">Amount</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.endorsement.basedOnAmount"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_based_on_amount">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_based_on_per">Per</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.endorsement.basedOnPer"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_based_on_per">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_endorsement_based_on_id">Based On</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_charge_percentage_basis_id', id: 'drp_charge_percentage_basis_id' }"
                                        :items="endorsementBasedOnOptions"
                                        v-model="data.endorsement.endorsementBasedOnID"
                                        value-expr="id"
                                        display-expr="name"
                                        :search-enabled="true"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_based_on_minimum">Min. Charge</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.endorsement.basedOnMinimum"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_based_on_minimum">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_based_on_maximum">Max. Charge</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.endorsement.basedOnMaximum"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_based_on_maximum">
                                        </rqInputNumber>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- Scalable -->
                        <template v-if="data.endorsement.endorsementChargeTypeID == 4">
                            <div class="row">
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="drp_endorsement_based_on_id2">Based On</label>
                                    <dx-select-box
                                        :input-attr="{ automation_id: 'drp_endorsement_based_on_id2', id: 'drp_endorsement_based_on_id2' }"
                                            :items="endorsementBasedOnOptions"
                                        v-model="data.endorsement.endorsementBasedOnID"
                                        value-expr="id"
                                        display-expr="name"
                                        :search-enabled="true"
                                    />
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_based_on_minimum2">Min. Charge</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.endorsement.basedOnMinimum"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_based_on_minimum2">
                                        </rqInputNumber>
                                    </div>
                                </div>
                                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                    <label for="txt_based_on_maximum2">Max. Charge</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <rqInputNumber
                                            v-model="data.endorsement.basedOnMaximum"
                                            defaultValue="0"
                                            decimals="2"
                                            cssClass="form-control"
                                            automation_id="txt_based_on_maximum2">
                                        </rqInputNumber>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <rqdx-action-data-grid
                                        ref="endorsementRateDataGrid"
                                        :automation_id="elementName('tbl', endorsementRateName)"
                                        :actions="endorsementRateSelectionActions"
                                        :config="endorsementRateGridConfig"
                                        :data-source="endorsementRateGridDataSource"
                                        :export-file-name="elementName('', endorsementRateName, 'data')"
                                        focus-after-insert="first-row"
                                        v-model:validation-errors="endorsementRateValidationErrors"
                                        @delete="onDeleteGridItem($event, 'EndorsementRate')"
                                        hide-search
                                        rq-editable>
                                        <template #toolbar>
                                            <ul class="nav">
                                                <li class="nav-item">
                                                    <b-button
                                                        automation_id="btn_add_endorsement_rate"
                                                        variant="theme"
                                                        :disabled="readOnly"
                                                        @click="onAddGridItem($event, 'EndorsementRate')">Add
                                                    </b-button>
                                                </li>
                                            </ul>
                                        </template>
                                    </rqdx-action-data-grid>
                                </div>
                            </div>
                        </template>
                        <!-- SalesPriceSlashLoanAmount -->
                        <template v-if="data.endorsement.endorsementChargeTypeID == 5">
                            <rq-section-card
                                :title="sections.calculations.labels[0]"
                                :section-group="sections.calculations.group"
                                collapsible>
                                    <div class="row">
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="drp_loan_policy_id">Loan Policy</label>
                                            <dx-select-box
                                                :input-attr="{ automation_id: 'drp_loan_policy_id', id: 'drp_loan_policy_id' }"
                                                :defaultItemEnabled="true"
                                                :items="simultaneousPolicyTypes"
                                                v-model="data.endorsement.salesPolicyID"
                                                value-expr="id"
                                                display-expr="name"
                                                :search-enabled="true"
                                            />
                                        </div>
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="txt_sales_policy_code">Code</label>
                                            <input automation_id="txt_sales_policy_code" v-model="data.endorsement.salesPolicyCode" type="text" class="form-control" placeholder="Code" maxlength="50">
                                        </div>
                                        <!-- Sales FlatFee -->
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-if="data.endorsement.salesPolicyID == 1">
                                            <label for="txt_sales_flat_fee_amount">Amount</label>
                                            <div class="input-group">
                                                <span class="input-group-text">$</span>
                                                <rqInputNumber
                                                    v-model="data.endorsement.salesFlatFeeAmount"
                                                    defaultValue="0"
                                                    decimals="2"
                                                    cssClass="form-control"
                                                    automation_id="txt_sales_flat_fee_amount">
                                                </rqInputNumber>
                                            </div>
                                        </div>
                                        <div class="col col-sm-12 col-lg-6 col-xl-3 form-group pt-label">
                                            <b-form-checkbox
                                                automation_id="chk_sales_use_premium_rounding"
                                                id="chk_sales_use_premium_rounding"
                                                :disabled="readOnly"
                                                v-model="data.endorsement.salesUsePremiumRounding"
                                                >Use Premium Rounding</b-form-checkbox>
                                        </div>
                                    </div>
                                    <!-- Sales FlatFeePlusExcess -->
                                    <div class="row" v-if="data.endorsement.salesPolicyID == 2">
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="txt_sales_flat_fee_excess_amount">Amount</label>
                                            <div class="input-group">
                                                <span class="input-group-text">$</span>
                                                <rqInputNumber
                                                    v-model="data.endorsement.salesFlatExcessAmount"
                                                    defaultValue="0"
                                                    decimals="2"
                                                    cssClass="form-control"
                                                    automation_id="txt_sales_flat_fee_excess_amount">
                                                </rqInputNumber>
                                            </div>
                                        </div>
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="drp_sales_flat_excess_policy">Excess Policy</label>
                                            <dx-select-box
                                                :input-attr="{ automation_id: 'drp_sales_flat_excess_policy', id: 'drp_sales_flat_excess_policy' }"
                                                :defaultItemEnabled="true"
                                                :items="getExcessPolicyRates(originalData.endorsement.salesFlatExcessPolicy)"
                                                v-model="data.endorsement.salesFlatExcessPolicy"
                                                value-expr="id"
                                                display-expr="name"
                                                :search-enabled="true"
                                            />
                                        </div>
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="drp_sales_flat_excess_calc_method_id">Excess Method</label>
                                            <dx-select-box
                                                :input-attr="{ automation_id: 'drp_sales_flat_excess_calc_method_id', id: 'drp_sales_flat_excess_calc_method_id' }"
                                                :defaultItemEnabled="false"
                                                :items="excessMethods"
                                                v-model="data.endorsement.salesFlatExcessCalcMethodID"
                                                value-expr="id"
                                                display-expr="name"
                                                :search-enabled="true"
                                            />
                                        </div>
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.endorsement.salesFlatExcessCalcMethodID === 1">
                                            <label for="txt_sales_flat_excess_calc_pct_diff">Excess % Diff</label>
                                            <div class="input-group">
                                                <rqInputNumber
                                                    v-model="data.endorsement.salesFlatExcessCalcPctDiff"
                                                    defaultValue="0"
                                                    decimals="3"
                                                    minValue="0"
                                                    maxValue="100"
                                                    cssClass="form-control"
                                                    automation_id="txt_sales_flat_excess_calc_pct_diff">
                                                </rqInputNumber>
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Sales Percentage -->
                                    <div class="row" v-if="data.endorsement.salesPolicyID == 4">
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="txt_sales_percentage_pct">Percent</label>
                                            <div class="input-group">
                                                <rqInputNumber
                                                    v-model="data.endorsement.salesPercentagePct"
                                                    defaultValue="0"
                                                    decimals="3"
                                                    minValue="0"
                                                    maxValue="100"
                                                    cssClass="form-control"
                                                    automation_id="txt_sales_percentage_pct">
                                                </rqInputNumber>
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Sales PercentagePlusExcess -->
                                    <div class="row" v-if="data.endorsement.salesPolicyID == 6">
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="txt_sales_percentage_excess_pct">Percent</label>
                                            <div class="input-group">
                                                <rqInputNumber
                                                    v-model="data.endorsement.salesPercentageExcessPct"
                                                    defaultValue="0"
                                                    decimals="3"
                                                    minValue="0"
                                                    maxValue="100"
                                                    cssClass="form-control"
                                                    automation_id="txt_sales_percentage_excess_pct">
                                                </rqInputNumber>
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="drp_sales_percentage_excess_policy">Excess Policy</label>
                                            <dx-select-box
                                                :input-attr="{ automation_id: 'drp_sales_percentage_excess_policy', id: 'drp_sales_percentage_excess_policy' }"
                                                :defaultItemEnabled="true"
                                                :items="getExcessPolicyRates(originalData.endorsement.salesPercentageExcessPolicy)"
                                                v-model="data.endorsement.salesPercentageExcessPolicy"
                                                value-expr="id"
                                                display-expr="name"
                                                :search-enabled="true"
                                            />
                                        </div>
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="drp_sales_percentage_excess_calc_method_id">Excess Method</label>
                                            <dx-select-box
                                                :input-attr="{ automation_id: 'drp_sales_percentage_excess_calc_method_id', id: 'drp_sales_percentage_excess_calc_method_id' }"
                                                :defaultItemEnabled="false"
                                                :items="excessMethods"
                                                v-model="data.endorsement.salesPercentageExcessCalcMethodID"
                                                value-expr="id"
                                                display-expr="name"
                                                :search-enabled="true"
                                            />
                                        </div>
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.endorsement.salesPercentageExcessCalcMethodID === 1">
                                            <label for="txt_sales_percentage_excess_calc_pct_diff">Excess % Diff</label>
                                            <div class="input-group">
                                                <rqInputNumber
                                                    v-model="data.endorsement.salesPercentageExcessCalcPctDiff"
                                                    defaultValue="0"
                                                    decimals="3"
                                                    minValue="0"
                                                    maxValue="100"
                                                    cssClass="form-control"
                                                    automation_id="txt_sales_percentage_excess_calc_pct_diff">
                                                </rqInputNumber>
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Sales FlatFeePlusPercentage -->
                                    <div class="row" v-if="data.endorsement.salesPolicyID == 7">
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="txt_sales_flat_fee_excess_amount">Flat Fee Amount</label>
                                            <div class="input-group">
                                                <span class="input-group-text">$</span>
                                                <rqInputNumber
                                                    v-model="data.endorsement.salesFlatFeePercentageAmount"
                                                    defaultValue="0"
                                                    decimals="2"
                                                    cssClass="form-control"
                                                    automation_id="txt_sales_flat_fee_excess_amount">
                                                </rqInputNumber>
                                            </div>
                                        </div>
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="txt_sales_flat_fee_pPercentage_pct">Percent</label>
                                            <div class="input-group">
                                                <rqInputNumber
                                                    v-model="data.endorsement.salesFlatFeePercentagePct"
                                                    defaultValue="0"
                                                    decimals="3"
                                                    minValue="0"
                                                    maxValue="100"
                                                    cssClass="form-control"
                                                    automation_id="txt_sales_flat_fee_pPercentage_pct">
                                                </rqInputNumber>
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Sales FlatFeePlusPercentagePlusExcess -->
                                    <div class="row" v-if="data.endorsement.salesPolicyID == 8">
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="txt_sales_flat_fee_percentage_excess_amount">Amount</label>
                                            <div class="input-group">
                                                <span class="input-group-text">$</span>
                                                <rqInputNumber
                                                    v-model="data.endorsement.salesFlatFeePercentageExcessAmount"
                                                    defaultValue="0"
                                                    decimals="2"
                                                    cssClass="form-control"
                                                    automation_id="txt_sales_flat_fee_percentage_excess_amount">
                                                </rqInputNumber>
                                            </div>
                                        </div>
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="txt_sales_flat_fee_percentage_excess_policy">Excess Policy</label>
                                            <dx-select-box
                                                :input-attr="{ automation_id: 'txt_sales_flat_fee_percentage_excess_policy', id: 'txt_sales_flat_fee_percentage_excess_policy' }"
                                                :defaultItemEnabled="true"
                                                :items="getExcessPolicyRates(originalData.endorsement.salesFlatFeePercentageExcessPolicy)"
                                                v-model="data.endorsement.salesFlatFeePercentageExcessPolicy"
                                                value-expr="id"
                                                display-expr="name"
                                                :search-enabled="true"
                                            />
                                        </div>
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="drp_sales_flat_percentage_excess_calc_method_id">Excess Method</label>
                                            <dx-select-box
                                                :input-attr="{ automation_id: 'drp_sales_flat_percentage_excess_calc_method_id', id: 'drp_sales_flat_percentage_excess_calc_method_id' }"
                                                :defaultItemEnabled="false"
                                                :items="excessMethods"
                                                v-model="data.endorsement.salesFlatFeePercentageExcessCalcMethodID"
                                                value-expr="id"
                                                display-expr="name"
                                                :search-enabled="true"
                                            />
                                        </div>
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.endorsement.salesFlatFeePercentageExcessCalcMethodID === 1">
                                            <label for="txt_sales_flat_percentage_excess_calc_pct_diff">Excess % Diff</label>
                                            <div class="input-group">
                                                <rqInputNumber
                                                    v-model="data.endorsement.salesFlatFeePercentageExcessCalcPctDiff"
                                                    defaultValue="0"
                                                    decimals="3"
                                                    minValue="0"
                                                    maxValue="100"
                                                    cssClass="form-control"
                                                    automation_id="txt_sales_flat_percentage_excess_calc_pct_diff">
                                                </rqInputNumber>
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="data.endorsement.salesPolicyID == 8">
                                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                            <label for="txt_sales_flat_fee_percentage_excess_pct">Percent</label>
                                            <div class="input-group">
                                                <rqInputNumber
                                                    v-model="data.endorsement.salesFlatFeePercentageExcessPct"
                                                    defaultValue="0"
                                                    decimals="3"
                                                    minValue="0"
                                                    maxValue="100"
                                                    cssClass="form-control"
                                                    automation_id="txt_sales_flat_fee_percentage_excess_pct">
                                                </rqInputNumber>
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                            </rq-section-card>
                            <rq-section-card
                                :title="sections.calculations.labels[1]"
                                :section-group="sections.calculations.group"
                                collapsible>
                                <div class="row">
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="drp_loan_policy_id">Loan Policy</label>
                                        <dx-select-box
                                            :input-attr="{ automation_id: 'drp_loan_policy_id', id: 'drp_loan_policy_id' }"
                                            :defaultItemEnabled="true"
                                            :items="simultaneousPolicyTypes"
                                            v-model="data.endorsement.loanPolicyID"
                                            value-expr="id"
                                            display-expr="name"
                                            :search-enabled="true"
                                        />
                                    </div>
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="txt_loan_policy_code">Code</label>
                                        <input automation_id="txt_loan_policy_code" v-model="data.endorsement.loanPolicyCode" type="text" class="form-control" placeholder="Code" maxlength="50">
                                    </div>
                                    <!-- Loan FlatFee -->
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-if="data.endorsement.loanPolicyID == 1">
                                        <label for="txt_loan_flat_fee_amount">Amount</label>
                                        <div class="input-group">
                                            <span class="input-group-text">$</span>
                                            <rqInputNumber
                                                v-model="data.endorsement.loanFlatFeeAmount"
                                                defaultValue="0"
                                                decimals="2"
                                                cssClass="form-control"
                                                automation_id="txt_loan_flat_fee_amount">
                                            </rqInputNumber>
                                        </div>
                                    </div>
                                    <div class="col col-sm-12 col-lg-6 col-xl-3 form-group pt-label">
                                        <b-form-checkbox
                                            automation_id="chk_loan_use_premium_rounding"
                                            id="chk_loan_use_premium_rounding"
                                            :disabled="readOnly"
                                            v-model="data.endorsement.loanUsePremiumRounding"
                                            >Use Premium Rounding</b-form-checkbox>
                                    </div>
                                </div>
                                <!-- Loan FlatFeePlusExcess -->
                                <div class="row" v-if="data.endorsement.loanPolicyID == 2">
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="txt_loan_flat_fee_excess_amount">Amount</label>
                                        <div class="input-group">
                                            <span class="input-group-text">$</span>
                                            <rqInputNumber
                                                v-model="data.endorsement.loanFlatExcessAmount"
                                                defaultValue="0"
                                                decimals="2"
                                                cssClass="form-control"
                                                automation_id="txt_loan_flat_fee_excess_amount">
                                            </rqInputNumber>
                                        </div>
                                    </div>
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="drp_loan_flat_excess_policy">Excess Policy</label>
                                        <dx-select-box
                                            :input-attr="{ automation_id: 'drp_loan_flat_excess_policy', id: 'drp_loan_flat_excess_policy' }"
                                            :defaultItemEnabled="true"
                                            :items="getExcessPolicyRates(originalData.endorsement.loanFlatExcessPolicy)"
                                            v-model="data.endorsement.loanFlatExcessPolicy"
                                            value-expr="id"
                                            display-expr="name"
                                            :search-enabled="true"
                                        />
                                    </div>
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="drp_loan_flat_excess_calc_method_id">Excess Method</label>
                                        <dx-select-box
                                            :input-attr="{ automation_id: 'drp_loan_flat_excess_calc_method_id', id: 'drp_loan_flat_excess_calc_method_id' }"
                                            :defaultItemEnabled="false"
                                            :items="excessMethods"
                                            v-model="data.endorsement.loanFlatExcessCalcMethodID"
                                            value-expr="id"
                                            display-expr="name"
                                            :search-enabled="true"
                                        />
                                    </div>
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.endorsement.loanFlatExcessCalcMethodID === 1">
                                        <label for="txt_loan_flat_excess_calc_pct_diff">Excess % Diff</label>
                                        <div class="input-group">
                                            <rqInputNumber
                                                v-model="data.endorsement.loanFlatExcessCalcPctDiff"
                                                defaultValue="0"
                                                decimals="3"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                automation_id="txt_loan_flat_excess_calc_pct_diff">
                                            </rqInputNumber>
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Loan Percentage -->
                                <div class="row" v-if="data.endorsement.loanPolicyID == 4">
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="txt_loan_percentage_pct">Percent</label>
                                        <div class="input-group">
                                            <rqInputNumber
                                                v-model="data.endorsement.loanPercentagePct"
                                                defaultValue="0"
                                                decimals="3"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                automation_id="txt_loan_percentage_pct">
                                            </rqInputNumber>
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Loan PercentagePlusExcess -->
                                <div class="row" v-if="data.endorsement.loanPolicyID == 6">
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="txt_loan_percentage_excess_pct">Percent</label>
                                        <div class="input-group">
                                            <rqInputNumber
                                                v-model="data.endorsement.loanPercentageExcessPct"
                                                defaultValue="0"
                                                decimals="3"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                automation_id="txt_loan_percentage_excess_pct">
                                            </rqInputNumber>
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="drp_loan_percentage_excess_policy">Excess Policy</label>
                                        <dx-select-box
                                            :input-attr="{ automation_id: 'drp_loan_percentage_excess_policy', id: 'drp_loan_percentage_excess_policy' }"
                                            :defaultItemEnabled="true"
                                            :items="getExcessPolicyRates(originalData.endorsement.loanPercentageExcessPolicy)"
                                            v-model="data.endorsement.loanPercentageExcessPolicy"
                                            value-expr="id"
                                            display-expr="name"
                                            :search-enabled="true"
                                        />
                                    </div>
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="drp_loan_percentage_excess_calc_method_id">Excess Method</label>
                                        <dx-select-box
                                            :input-attr="{ automation_id: 'drp_loan_percentage_excess_calc_method_id', id: 'drp_loan_percentage_excess_calc_method_id' }"
                                            :defaultItemEnabled="false"
                                            :items="excessMethods"
                                            v-model="data.endorsement.loanPercentageExcessCalcMethodID"
                                            value-expr="id"
                                            display-expr="name"
                                            :search-enabled="true"
                                        />
                                    </div>
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.endorsement.loanPercentageExcessCalcMethodID === 1">
                                        <label for="txt_loan_percentage_excess_calc_pct_diff">Excess % Diff</label>
                                        <div class="input-group">
                                            <rqInputNumber
                                                v-model="data.endorsement.loanPercentageExcessCalcPctDiff"
                                                defaultValue="0"
                                                decimals="3"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                automation_id="txt_loan_percentage_excess_calc_pct_diff">
                                            </rqInputNumber>
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Loan FlatFeePlusPercentage -->
                                <div class="row" v-if="data.endorsement.loanPolicyID == 7">
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="txt_loan_flat_fee_excess_amount">Flat Fee Amount</label>
                                        <div class="input-group">
                                            <span class="input-group-text">$</span>
                                            <rqInputNumber
                                                v-model="data.endorsement.loanFlatFeePercentageAmount"
                                                defaultValue="0"
                                                decimals="2"
                                                cssClass="form-control"
                                                automation_id="txt_loan_flat_fee_excess_amount">
                                            </rqInputNumber>
                                        </div>
                                    </div>
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="txt_loan_flat_fee_pPercentage_pct">Percent</label>
                                        <div class="input-group">
                                            <rqInputNumber
                                                v-model="data.endorsement.loanFlatFeePercentagePct"
                                                defaultValue="0"
                                                decimals="3"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                automation_id="txt_loan_flat_fee_pPercentage_pct">
                                            </rqInputNumber>
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Loan FlatFeePlusPercentagePlusExcess -->
                                <div class="row" v-if="data.endorsement.loanPolicyID == 8">
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="txt_loan_flat_fee_percentage_excess_amount">Amount</label>
                                        <div class="input-group">
                                            <span class="input-group-text">$</span>
                                            <rqInputNumber
                                                v-model="data.endorsement.loanFlatFeePercentageExcessAmount"
                                                defaultValue="0"
                                                decimals="2"
                                                cssClass="form-control"
                                                automation_id="txt_loan_flat_fee_percentage_excess_amount">
                                            </rqInputNumber>
                                        </div>
                                    </div>
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="txt_loan_flat_fee_percentage_excess_policy">Excess Policy</label>
                                        <dx-select-box
                                            :input-attr="{ automation_id: 'txt_loan_flat_fee_percentage_excess_policy', id: 'txt_loan_flat_fee_percentage_excess_policy' }"
                                            :defaultItemEnabled="true"
                                            :items="getExcessPolicyRates(originalData.endorsement.loanFlatFeePercentageExcessPolicy)"
                                            v-model="data.endorsement.loanFlatFeePercentageExcessPolicy"
                                            value-expr="id"
                                            display-expr="name"
                                            :search-enabled="true"
                                        />
                                    </div>
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="drp_loan_flat_percentage_excess_calc_method_id">Excess Method</label>
                                        <dx-select-box
                                            :input-attr="{ automation_id: 'drp_loan_flat_percentage_excess_calc_method_id', id: 'drp_loan_flat_percentage_excess_calc_method_id' }"
                                            :defaultItemEnabled="false"
                                            :items="excessMethods"
                                            v-model="data.endorsement.loanFlatFeePercentageExcessCalcMethodID"
                                            value-expr="id"
                                            display-expr="name"
                                            :search-enabled="true"
                                        />
                                    </div>
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-show="data.endorsement.loanFlatFeePercentageExcessCalcMethodID === 1">
                                        <label for="txt_loan_flat_percentage_excess_calc_pct_diff">Excess % Diff</label>
                                        <div class="input-group">
                                            <rqInputNumber
                                                v-model="data.endorsement.loanFlatFeePercentageExcessCalcPctDiff"
                                                defaultValue="0"
                                                decimals="3"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                automation_id="txt_loan_flat_percentage_excess_calc_pct_diff">
                                            </rqInputNumber>
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="data.endorsement.loanPolicyID == 8">
                                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                        <label for="txt_loan_flat_fee_percentage_excess_pct">Percent</label>
                                        <div class="input-group">
                                            <rqInputNumber
                                                v-model="data.endorsement.loanFlatFeePercentageExcessPct"
                                                defaultValue="0"
                                                decimals="3"
                                                minValue="0"
                                                maxValue="100"
                                                cssClass="form-control"
                                                automation_id="txt_loan_flat_fee_percentage_excess_pct">
                                            </rqInputNumber>
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                            </rq-section-card>
                        </template>
                    </fieldset>
                </rq-page-section>
            </template>

            <template #coverageRounding >
                <rq-page-section title="Coverage Rounding" headerSize="md" borderless>
                    <div class="row">
                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                            <label for="drp_coverage_rounding_method">Rounding Method</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_coverage_rounding_method', id: 'drp_coverage_rounding_method' }"
                                :defaultItemEnabled="false"
                                :items="coverageRoundingMethods"
                                v-model="data.endorsement.coverageRoundingMethod"
                                value-expr="id"
                                display-expr="name"
                                :search-enabled="true"
                                :disabled="readOnly"
                            />
                        </div>
                    </div>

                    <!-- Custom Coverage Rounding -->
                    <rqdx-action-data-grid
                        v-if="data.endorsement.coverageRoundingMethod === 4"
                        ref="coverageRoundingDataGrid"
                        :automation_id="elementName('tbl', coverageRoundingName)"
                        :actions="coverageRoundingSelectionActions"
                        :config="coverageRoundingGridConfig"
                        :data-source="coverageRoundingGridDataSource"
                        :export-file-name="elementName('', coverageRoundingName, 'data')"
                        focus-after-insert="first-row"
                        v-model:validation-errors="coverageRoundingValidationErrors"
                        :rq-editable="!readOnly"
                        @delete="onDeleteGridItem($event, 'CoverageRounding')"
                        hide-search>
                        <template #toolbar>
                            <ul class="nav">
                                <li class="nav-item">
                                    <b-button
                                        automation_id="btn_add_coverage_rounding"
                                        variant="theme"
                                        :disabled="readOnly"
                                        @click="onAddGridItem($event, 'CoverageRounding')">Add
                                    </b-button>
                                </li>
                            </ul>
                        </template>
                    </rqdx-action-data-grid>
                </rq-page-section>
            </template>

            <template #chargesFeeRounding >
                <rq-page-section title="Charges/Fee Rounding" headerSize="md" borderless>
                    <div class="row">
                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                            <label for="drp_endorsement_charges_settlement_fee_rounding_method">Rounding Method</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_endorsement_charges_settlement_fee_rounding_method', id: 'drp_endorsement_charges_settlement_fee_rounding_method' }"
                                :items="roundingMethods"
                                value-expr="id"
                                display-expr="name"
                                :search-enabled="true"
                                :disabled="readOnly"
                                v-model="data.endorsement.endorsementChargesSettlementFeeRoundingMethod"
                            />
                        </div>
                    </div>

                    <!-- Custom Endorsement Rounding -->
                    <rqdx-action-data-grid
                        v-if="data.endorsement.endorsementChargesSettlementFeeRoundingMethod === 4"
                        ref="endorsementRoundingDataGrid"
                        :automation_id="elementName('tbl', endorsementRoundingName)"
                        :actions="endorsementRoundingSelectionActions"
                        :config="endorsementRoundingGridConfig"
                        :data-source="endorsementRoundingGridDataSource"
                        :export-file-name="elementName('', endorsementRoundingName, 'data')"
                        focus-after-insert="first-row"
                        v-model:validation-errors="chargesRoundingValidationErrors"
                        :rq-editable="!readOnly"
                        @delete="onDeleteGridItem($event, 'EndorsementRounding')"
                        hide-search>
                        <template #toolbar>
                            <ul class="nav">
                                <li class="nav-item">
                                    <b-button
                                        automation_id="btn_add_endorsement_rounding"
                                        variant="theme"
                                        :disabled="readOnly"
                                        @click="onAddGridItem($event, 'EndorsementRounding')">Add
                                    </b-button>
                                </li>
                            </ul>
                        </template>
                    </rqdx-action-data-grid>
                </rq-page-section>
            </template>

            <template #documents >
                <rq-page-section title="Documents" borderless>
                    <grid-selector
                        id="taskGridSelector"
                        :items="documentTemplates"
                        :item-key="docItemKey"
                        item-name="Documents"
                        :filter-items="regions"
                        filter-item-key="regionID"
                        filter-item-name="Region"
                        filter-text-field="displayName"
                        filter-value-field="regionID"
                        :grid-configs="gridConfigs"
                        :read-only="readOnly"
                        v-model="data.documentTemplateIDs"
                        hide-reorder
                    />
                </rq-page-section>
            </template>
        </RqTabs>
    </div>
</template>

<script>
    import { ref, computed } from "vue";
    import { required } from "@vuelidate/validators";
    import { useVuelidate } from "@vuelidate/core";
    import { mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import GridSelector from "../../shared/GridSelector";
    import { EndorsementDataDto, DocumentTemplateShortDto, RateEngineEndorsementRateDto }  from "../models";
    import { ClosingParties, TaxType, TaxRateEffectiveBasis, TaxRateBasedOn, SimultaneousPolicyType, ExcessMethod, CoverageRoundingMethod, RoundingMethod, RoundingRule, RoundingRowType }  from "../../enums";
    import { SystemLookupItem } from "@/shared/models/models";
    import { EndorsementPolicy, BreakoutEndorsementOption, EndorsementChargeType, EndorsementBasedOn, EndorsementYesNo, UnderwriterSplitOpts } from "@settlement/models/enums";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import PremiumPicker from './PremiumPicker';
    import { dateTimeCalculated } from "@/shared/utilities/VueHelpers";
    import { useRqTabAlerts } from "@/shared/composables/useRqTabAlerts";
    import { DateTime } from "luxon";

    export default {
        name: "EndorsementForm",
        components: { GridSelector, PremiumPicker },
        setup() {
            const endorsementRateValidationErrors = ref([]);
            const chargesRoundingValidationErrors = ref([]);
            const coverageRoundingValidationErrors = ref([]);
            const hasGridValidationErrors = computed(() => !_.isEmpty(endorsementRateValidationErrors.value)
                || !_.isEmpty(chargesRoundingValidationErrors.value)
                || !_.isEmpty(coverageRoundingValidationErrors.value)
            );

            const v$ = useVuelidate();
            const alertMapping = {
                detailsTabAlert: { type: "v$" },
                calculationsTabAlert: { type: "list-ref", list: endorsementRateValidationErrors },
                coverageRoundingTabAlert: { type: "list-ref", list: coverageRoundingValidationErrors },
                chargesRoundingTabAlert: { type: "list-ref", list: chargesRoundingValidationErrors }
            };

            const {
                detailsTabAlert,
                calculationsTabAlert,
                coverageRoundingTabAlert,
                chargesRoundingTabAlert
            } = useRqTabAlerts({ v$, alertMapping });

            return {
                v$,
                endorsementRateValidationErrors,
                chargesRoundingValidationErrors,
                coverageRoundingValidationErrors,
                hasGridValidationErrors,
                detailsTabAlert,
                calculationsTabAlert,
                coverageRoundingTabAlert,
                chargesRoundingTabAlert
            };
        },
        data() {
            return {
                data: {
                    endorsement: {}
                },
                originalData: {},
                regionIDs: [],
                underwriters: [],
                currentTabIndex: 0,
                sections: {
                    calculations: {
                        group: "calculations-group",
                        labels: [
                            "When Sales Price Is Greater Than Or Equal To Loan Amount",
                            "When Sales Price Less Than Loan Amount"
                        ]
                    }
                },
                gridRefs: [
                    "coverageRoundingDataGrid",
                    "endorsementRateDataGrid",
                    "endorsementRoundingDataGrid"
                ],
                showBanner: false
            };
        },
        validations: () => ({
            data: {
                endorsement: {
                    code: { required },
                    description: { required },
                    endorsementPolicyTypeID: { required }
                },
                premiumTypeIDs: { required }
            },
            regionIDs: { required },
        }),
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            editTitle() { return `Edit Endorsement: ${_.get(this.data, "endorsement.description", "(Blank)")}`; },
            endorsementID() { return _.parseNumber(_.get(this.data, "endorsement.endorsementID", 0), 0); },
            hasEndorsement() { return !_.isEmpty(_.get(this.data, "endorsement")); },
            isNew() { return this.endorsementID === 0; },
            isAlta() { return !_.getIsEqual(this, "data.endorsement.state", "TX"); },
            isStateSelected() { return !_.getIsNullOrEmpty(this, "data.endorsement.state"); },
            enabledInputBox() { return _.getNumber(this, "data.endorsement.underwriterSplitType", 0) !== 2; },
            ...dateTimeCalculated({ field: { calcLastModified: 'data.endorsement.lastModified' }}),
            readOnly() { return !_.parseBool(_.get(this.data, "endorsement.canEdit", false), false); },
            hasValidationErrors(){ return this.hasGridValidationErrors|| this.v$.$error;},

            //lookups
            stateCounties() { return this.lookupHelpers.getCountiesByState(_.get(this, "data.endorsement.state", null)); },
            accountingCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES); },
            orderServices() { return this.lookupHelpers.getOrderServices(this.isAlta); },
            orderCategories() { return this.lookupHelpers.getLookupItems(this.lookupItems.ORDER_CATEGORIES, null, this.data.orderCategoryIDs); },
            tabItems() { return [
                    { automation_id: "btn_tab_details", name: "details", title: "Details", alertCount: this.detailsTabAlert.alertCount },
                    { automation_id: "btn_tab_calculations", name: "calculations", title: "Calculations", alertCount: this.calculationsTabAlert.alertCount },
                    { automation_id: "btn_tab_coverage_rounding", name: "coverageRounding", title: "Coverage Rounding", alertCount: this.coverageRoundingTabAlert.alertCount },
                    { automation_id: "btn_tab_charges_fee_rounding", name: "chargesFeeRounding", title: "Charges/Fee Rounding", alertCount: this.chargesRoundingTabAlert.alertCount },
                    { automation_id: "btn_tab_documents", name: "documents", title: "Documents" }
                ]
            }
        },
        watch: {
            // TG -- Just....no...whatever functionality this was trying to accomodate is better off reverted and readdressed than the issues this was causing
            // "data.endorsement.coverageRoundingMethod": function(newValue, oldValue){
            //     // Remove the Coverage Rounding Grid changes and validation
            //     var self = this;
            //     if(newValue !== 4){
            //         setTimeout(function() {
            //             self.resetGrid();
            //         }, 100)
            //     }
            // },
            // "data.endorsement.endorsementChargesSettlementFeeRoundingMethod": function(newValue, oldValue){
            //     // Remove the Changes/Fee Rounding Grid changes and validation
            //     var self = this;
            //     if(newValue !== 4){
            //         setTimeout(function() {
            //             self.resetGrid();
            //         }, 100)
            //     }
            // },
            currentTabIndex(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.processTabChange(newValue);
            }
        },
        created() {
            GlobalEventManager.onSave(this, this.onSave);
            let endorsementID = _.parseNumber(_.get(this.$route, "params.endorsementID", 0), 0);
            this.fetchData(endorsementID);
            this.initNonReactiveVariables();
            this.initGridConfig();
        },
        beforeRouteLeave(to, from, next) {
            const self = this;
            let changes = self.getAuditChanges(self.originalData.toDataObject(), self.data.toDataObject());
            if ((self.isCancelled || self.readOnly) || (changes.length == 0 && !self.childComponentsHaveChanges())){
                GlobalEventManager.saveCompleted({ success: true });
                next();
                return;
            } else {
                self.save({ userInitiated: true })
                    .then((isValid) => {
                        if(!isValid) return;
                        next();
                        return;
                    });
            }
        },
        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },
        methods: {
            getDate(node="") {
                let date = _.get(this, node) || null;
                return _.isNil(date) ? "" : DateTime.fromISO(date).toFormat('MM/dd/yyyy, hh:mm a');
            },
            cancelChildComponentChanges(){
                const self = this;
                _.each(self.gridRefs, refName => {
                    self.invokeGridMethod(refName, "cancelEditData");
                });
            },
            childComponentsHaveChanges(){
                const self = this;
                return _.some(self.gridRefs, refName => self.invokeGridMethod(refName, "hasEditData"));
            },
            elementName(prefix="", item="", suffix="") { return _.snakeCase(`${prefix} ${item} ${suffix}`); },
            fetchData(endorsementID) {
                const self = this;
                let apiPromise = self.$api.EndorsementsApi.get(endorsementID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.data = new EndorsementDataDto(_.cloneDeep(result));
                        self.originalData = new EndorsementDataDto(_.cloneDeep(result));
                        self.regionIDs = _.clone(self.data.regionIDs);
                        self.underwriters = self.lookupHelpers.getUnderwriters(_.clone(self.data.regionIDs));
                        self.rateEngineEndorsementRates = _.map(result.rateEngineEndorsementRates, i => new RateEngineEndorsementRateDto(i)) || [];
                        self.excessPolicyRates = _.map(result.excessPolicyRates, i => new SystemLookupItem(i));
                        self.cdfLineTypes = _.map(result.cdfLineTypes, i => new SystemLookupItem(i));
                        self.hudLineTypes = _.map(result.hudLineTypes, i => new SystemLookupItem(i));
                        self.documentTemplates = _.map(result.documentTemplates, i => new DocumentTemplateShortDto(i));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.endorsementRateNamePlural}.` });
                    });
            },
            getGrid(type) {
                return _.get(this.$refs, `${_.camelCase(type)}DataGrid.gridInstance`, null) || {};
            },
            getExcessPolicyRates(selectedID=-1) {
                return _.filter(this.excessPolicyRates, item => item.inactive == false || item.id == selectedID);
            },
            getRoundingGridConfig(roundingRowType) {
                const self = this;
                return _.clone({
                    paging: { enabled: false },
                    height: function() {
                        return window.innerHeight - 430;
                    },
                    remoteOperations: { sorting: false, paging: false },
                    columns: [
                        {
                            dataField: "fromAmount",
                            caption: "From",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            allowEditing: false,
                            sortIndex: 0,
                            sortOrder: "asc",
                        },
                        {
                            dataField: "toAmount",
                            caption: "To",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isToAmountLessThanFromAmount,
                                    message: "To Amount must be greater than From Amount"
                                }                            
                            ]
                        },
                        {
                            dataField: "rateEngineRoundingRuleID",
                            caption: "Rounding",
                            dataType: "number",
                            lookup: {
                                dataSource: self.roundingRules,
                                displayExpr: "name",
                                valueExpr: "id"
                            }, validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "nearestIncrement",
                            caption: "Nearest",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        }
                    ],
                    onInitNewRow: function(e) {
                        e.data.fromAmount = self.getNextRateFromAmount(roundingRowType)
                    }
                });
            },
            getRoundingRowTypeName(roundingRowType) {
                switch (roundingRowType) {
                    case RoundingRowType.None:
                        return "EndorsementRate";
                    case RoundingRowType.CoverageRounding:
                        return "CoverageRounding";
                    case RoundingRowType.EndorsementChargesSettlementFeeRounding:
                        return "EndorsementRounding";
                    default:
                        return "Unknown";
                }
            },
            getNextRateFromAmount(roundingRowType){
                let list = _.filter(this.rateEngineEndorsementRates, r => r.roundingRowType === roundingRowType)
                let maxAmount = _.maxBy(list, 'toAmount');
                return maxAmount ? maxAmount.toAmount + .01 : 0;
            },
            isToAmountLessThanFromAmount(item) {
                return _.gt(item.data.toAmount, item.data.fromAmount);
            },
            initNonReactiveVariables() {
                const self = this;
                self.entityName = _.get(self.$route.meta, "itemTypeName");
                self.entityDescription = _.get(self.$route.meta, "itemTypeDescription");
                self.docItemKey = "documentTemplateID";
                self.endorsementRateName = "Rate Engine Endorsement Rate";
                self.endorsementRateNamePlural = "Rate Engine Endorsement Rates";
                self.endorsementRateKey = "rateEngineEndorsementRateID";
                self.endorsementRateSelectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.endorsementRateName}`
                    }
                ];
                self.coverageRoundingKey = "rateEngineEndorsementRateID";
                self.coverageRoundingName = "Coverage Rounding";
                self.coverageRoundingNamePlural = "Coverage Roundings";
                self.coverageRoundingSelectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.coverageRoundingName}`
                    }
                ];
                self.endorsementRoundingKey = "rateEngineEndorsementRateID";
                self.endorsementRoundingName = "Endorsement/Settlement Fee Rounding";
                self.endorsementRoundingNamePlural = "Endorsement/Settlement Fee Roundings";
                self.endorsementRoundingSelectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.endorsementRoundingName}`
                    }
                ];
                self.endorsementPolicyTypes = EndorsementPolicy.lookupItems;
                self.whoPays = ClosingParties.lookupItems;
                self.breakoutOptions = BreakoutEndorsementOption.lookupItems;
                self.endorsementYesNoOptions = EndorsementYesNo.lookupItems;
                self.underwriterSplitOptions = UnderwriterSplitOpts.lookupItems;
                self.taxRateEffectiveBasisOptions = TaxRateEffectiveBasis.lookupItems;
                self.taxRateBasedOnOptions = TaxRateBasedOn.lookupItems;
                self.taxContactRoles = self.lookupHelpers.getRoles();
                self.taxTypes = TaxType.lookupItems;
                self.endorsementChargeTypes = EndorsementChargeType.lookupItems;
                self.endorsementBasedOnOptions = EndorsementBasedOn.lookupItems;
                self.simultaneousPolicyTypes = SimultaneousPolicyType.lookupItems;
                self.excessMethods = ExcessMethod.lookupItems;
                self.coverageRoundingMethods = CoverageRoundingMethod.lookupItems;
                self.roundingMethods = RoundingMethod.lookupItems;
                self.roundingRules = RoundingRule.lookupItems;
                self.regions = self.lookupHelpers.getRegions();
                self.usStates = self.lookupHelpers.getStates();
            },
            initGridConfig(){
                const self = this;
                self.endorsementRateGridConfig = {
                    paging: { enabled: false },
                    remoteOperations: { sorting: false, paging: false },
                    height: function() {
                        return window.innerHeight - 500;
                    },
                    columns: [
                        {
                            dataField: "fromAmount",
                            caption: "From",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            allowEditing: false,
                            sortIndex: 0,
                            sortOrder: "asc",
                        },
                        {
                            dataField: "toAmount",
                            caption: "To",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isToAmountLessThanFromAmount,
                                    message: "To Amount must be greater than From Amount"
                                }                            
                            ]
                        },
                        {
                            dataField: "rate",
                            caption: "Rate",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 5
                            },
                            editorOptions: {
                                format: "$ #,##0.#####"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        {
                            dataField: "usePerRate",
                            caption: "Range",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        {
                            dataField: "per",
                            caption: "Per",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        {
                            dataField: "maxRate",
                            allowEditing: false,
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        {
                            caption: "Underwriter",
                            alignment: "center",
                            columns: [
                            {
                                dataField: "underwriterSplitPercentage",
                                dataType: "number",
                                caption: "Percentage",
                                format: {
                                    type: "percent"
                                },
                                editorOptions: {
                                    format: "##0.## %",
                                    min:0,
                                    max:1,
                                },
                            },
                            {
                                dataField: "underwriterSplitAmount",
                                caption: "Amount",
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: "$ #,##0.##"
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                            },
                            {
                                dataField: "underwriterSplitAmountPer",
                                caption: "Per",
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: "$ #,##0.##"
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                            },
                            {
                                dataField: "underwriterSplitMaxRate",
                                allowEditing: false,
                                caption: "Max Split",
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: "$ #,##0.##"
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                            }],
                        }
                    ],
                    onInitNewRow: function(e) {
                        e.data.fromAmount = self.getNextRateFromAmount(RoundingRowType.None);
                        e.data.usePerRate = false;
                    }
                };

                self.endorsementRateGridDataSource = {
                    key: self.endorsementRateKey,
                    load () {
                        return Promise.resolve(_.filter(self.rateEngineEndorsementRates, r => r.roundingRowType === RoundingRowType.None));
                    },
                    insert: self.onEndorsementRateGridInsert,
                    update: self.onEndorsementRateGridUpdate
                };
                self.coverageRoundingGridConfig = self.getRoundingGridConfig(RoundingRowType.CoverageRounding);

                self.endorsementRoundingGridConfig = self.getRoundingGridConfig(RoundingRowType.EndorsementChargesSettlementFeeRounding);

                self.endorsementRoundingGridDataSource = {
                    key: self.endorsementRateKey,
                    load () {
                        return Promise.resolve(_.filter(self.rateEngineEndorsementRates, r => r.roundingRowType === RoundingRowType.EndorsementChargesSettlementFeeRounding));
                    },
                    insert: self.onEndorsementRoundingGridInsert,
                    update: self.onEndorsementRateGridUpdate
                };

                self.coverageRoundingGridDataSource = {
                    key: self.endorsementRateKey,
                    load () {
                        return Promise.resolve(_.filter(self.rateEngineEndorsementRates, r => r.roundingRowType === RoundingRowType.CoverageRounding));
                    },
                    insert: self.onCoverageRoundingGridInsert,
                    update: self.onEndorsementRateGridUpdate
                };

                self.gridConfigs = {
                    columns: [
                        {
                            dataField: "description",
                            dataType: "string",
                            sortIndex: 0,
                            sortOrder: "asc",
                        },
                        {
                            dataField: "regionID",
                            caption: "Region",
                            dataType: "number",
                            allowSearch: false,
                            lookup: {
                                displayExpr: "displayName",
                                valueExpr: "regionID",
                                dataSource: self.regions
                            },
                            calculateSortValue: "regID"
                        }
                    ],
                };
            },
            onAddGridItem(e, type) {
                let grid = this.getGrid(type);
                if(!grid) return;
                grid.addRow();
            },
            onChangeState(e) {
                this.data.countyIDs = [];
                this.data.premiumTypeIDs = [];
            },
            onRegionChange(e) {
                const self = this;
                self.data.regionIDs = _.clone(e.value);
                if (self.data.regionIDs.length > 0) {
                    self.underwriters = self.lookupHelpers.getUnderwriters(_.clone(e.value));
                    let newUnderWriters = _.map(self.underwriters, 'id');
                    let removeIDs = _.difference(self.data.underwriterIDs, newUnderWriters);
                    _.pullAll(self.data.underwriterIDs, removeIDs);
                } else {
                    self.underwriters = [];
                }
            },
            processTabChange(tabIndex) {
                const self = this;
                self.save({ userInitiated: false });
                self.$nextTick(() => {
                    switch(tabIndex) {
                        case 1: self.refreshGrid(RoundingRowType.None); break;
                        case 2: self.refreshGrid(RoundingRowType.CoverageRounding); break;
                        case 3: self.refreshGrid(RoundingRowType.EndorsementChargesSettlementFeeRounding); break;
                    }
                });
            },
            onDeleteGridItem(e, type) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 0 ? _.get(self, `${_.camelCase(type)}Name`) : _.get(self, `${_.camelCase(type)}NamePlural`);
                let itemKey = _.get(self, `${_.camelCase(type)}Key`);
                let ok = function (args) {
                    let toBeDeletedKeys = items.map(item => _.get(item, itemKey));

                    let apiPromise = self.$api.EndorsementsApi.deleteRate(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.removeGridItems(items, itemKey);
                            let message = key.length > 1 ? `${key.length} ${itemLabel} were deleted.` : `${itemLabel} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${itemLabel}.` });
                            return error;
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${itemLabel}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onEndorsementRateGridInsert(values, roundingRowType=0) {
                const self = this;
                let newItem = new RateEngineEndorsementRateDto({...values, roundingRowType, endorsementID: self.endorsementID});
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.saveRate(newItem, changes)
                    .then(result => {
                        self.rateEngineEndorsementRates.push(new RateEngineEndorsementRateDto(_.clone(result)));
                        self.refreshGrid(roundingRowType);
                    });
            },
            onEndorsementRoundingGridInsert(values) {
                return this.onEndorsementRateGridInsert(values, RoundingRowType.EndorsementChargesSettlementFeeRounding);
            },
            onCoverageRoundingGridInsert(values) {
                return this.onEndorsementRateGridInsert(values, RoundingRowType.CoverageRounding);
            },
            onEndorsementRateGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.rateEngineEndorsementRates, item => _.parseNumber(_.get(item, self.endorsementRateKey), -1) === key);
                if(itemIndex < 0) return self.onEndorsementRateGridInsert(values);

                let originalItem = _.cloneDeep(self.rateEngineEndorsementRates[itemIndex]);
                let updatedItem = new RateEngineEndorsementRateDto(_.assign({}, self.rateEngineEndorsementRates[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.saveRate(updatedItem, changes)
                    .then(result => {
                        self.rateEngineEndorsementRates[itemIndex] = new RateEngineEndorsementRateDto(_.clone(result));
                        self.refreshGrid(self.rateEngineEndorsementRates[itemIndex].roundingRowType);
                    });
            },
            onCancel(e) {
                const self = this;
                self.v$.$reset();
                let changes = self.getAuditChanges(self.originalData, self.data);
                let ok = function (args) {
                    self.cancelChildComponentChanges();
                    self.data = new EndorsementDataDto(_.cloneDeep(self.originalData));
                    self.regionIDs = _.clone(self.data.regionIDs);
                    self.underwriters = self.lookupHelpers.getUnderwriters(_.clone(self.data.regionIDs));
                };
                if ((changes.length === 0 || self.readOnly) && !self.childComponentsHaveChanges()) {
                    self.$toast.info("No changes detected");
                } else {
                    self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", ok);
                }
            },
            onSave(e) {
                const self = this;
                let failureMsg = "Unable to save changes.";
                if(_.isEmpty(e)) // true
                {
                    GlobalEventManager.saveCompleted({success: false});
                        return Promise.resolve(false);
                }
                else
                {
                    self.save(e)
                        .then(resolved => {
                            if(!resolved)
                            {
                                self.$toast.error(failureMsg);
                            }
                            GlobalEventManager.saveCompleted({success: resolved});
                            return Promise.resolve(resolved);
                        })
                        .catch(error => {
                            self.$toast.error(failureMsg);
                            GlobalEventManager.saveCompleted({success: false});
                            return Promise.resolve(false);
                        });
                }
            },
            onUnderwriterSplitChange(e) {
                let oldValue = e.previousValue;
                let newValue = e.value;

                if (newValue == oldValue) return;
                if (newValue !== 2){
                    this.data.endorsement.splitOverridePCT = 0;
                    this.data.endorsement.noUnderwriterSplit = true;
                    this.data.endorsement.overrideDefaultSplitRulesYN = true;
                }else{
                    this.data.endorsement.splitOverridePCT = this.originalData.endorsement.splitOverridePCT;
                    this.data.endorsement.noUnderwriterSplit = false;
                    this.data.endorsement.overrideDefaultSplitRulesYN = this.originalData.endorsement.overrideDefaultSplitRulesYN;
                }
            },
            onUnderwriterSplitPctBlur(e) {
               this.data.endorsement.overrideDefaultSplitRulesYN = (_.parseNumber(e.value, 0) < 100);
            },
            refreshGrid(roundingRowType) {
                let type = this.getRoundingRowTypeName(roundingRowType);
                let grid = this.getGrid(type);
                const gridHeightOffset = (roundingRowType == RoundingRowType.None) ? 500 : 430;
                if (!_.isEmpty(grid)) {
                    grid.option("height", window.innerHeight - gridHeightOffset);
                    grid.updateDimensions();
                    grid.option("focusedRowIndex", -1);
                    grid.clearSelection();
                    grid.refresh();
                }
            },
            removeGridItems(items, key) {
                _.pullAllBy(this.rateEngineEndorsementRates, items, key);
                this.refreshGrid(items[0].roundingRowType);
            },

            invokeGridMethod(refName, method, ...params) {
                return this.invokeGridComponentMethod(refName, "invokeGridMethod", method, ...params);
            },

            invokeGridComponentMethod(refName, method, ...params) {
                return _.invoke(this, `$refs.${refName}.${method}`, ...params);
            },

            saveChildComponents(){
                const self = this;
                let promises = _.map(self.gridRefs, refName => self.invokeGridComponentMethod(refName, "saveRow"));
                return Promise.all(promises);
            },

            // resetGrid(){
            //     const self = this;

            //     if(self.$refs.coverageRoundingDataGrid)
            //         self.$refs.coverageRoundingDataGrid.reset();
            //     if(self.$refs.endorsementRoundingDataGrid)
            //         self.$refs.endorsementRoundingDataGrid.reset();

            //     self.chargesRoundingValidationErrors = [];
            //     self.chargesRoundingValidationErrors = [];
            // },

            save(args={}) {
                const self = this;
                self.showBanner = true;
                self.v$.$touch();

                let changes = self.getAuditChanges(self.originalData.toDataObject(), self.data.toDataObject());
                if (self.readOnly || (changes.length === 0 && !self.childComponentsHaveChanges())){
                    if(_.getBool(args, "userInitiated")) self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({ success: true });
                    return Promise.resolve(true) ;
                }

                if (self.v$.$error) {
                    GlobalEventManager.saveCompleted({ success: false });
                    return Promise.resolve(false);
                }
                if (!self.data.endorsement.canEdit) {
                    GlobalEventManager.saveCompleted({ success: true });
                    return Promise.resolve(true);
                }
                return self.saveChildComponents()
                    .then(() => {
                        if (self.hasGridValidationErrors) {
                            GlobalEventManager.saveCompleted({ success: false });
                            return false;
                        }

                        self.showBanner = false;
                        if (changes.length > 0) {
                            let apiPromise = self.$api.EndorsementsApi.save(self.data, changes);
                            return self.$rqBusy.wait(apiPromise)
                                .then(result => {
                                    if (_.getBool(args, "userInitiated")) self.$toast.success({ message: `${self.entityName} was saved.` });
                                    let routeID = _.parseNumber(_.get(this.$route, "params.endorsementID", 0), 0);
                                    let resultID = _.parseNumber(_.get(result, "endorsement.endorsementID", 0), 0);
                                    if (!_.isEqual(routeID, resultID)) {
                                        GlobalEventManager.saveCompleted({ success: true });
                                        self.$router.push({ name: 'cfg:sscalc:endorsementsandfee', params: { endorsementID: resultID }});
                                        self.fetchData(resultID);
                                    } else {
                                        self.data = new EndorsementDataDto(_.cloneDeep(result));
                                        self.originalData = new EndorsementDataDto(_.cloneDeep(result));
                                        GlobalEventManager.saveCompleted({ success: true });
                                        return true;
                                    }
                                })
                                .catch(error => {
                                    self.$toast.error(`Error Saving ${self.entityName}`);
                                    GlobalEventManager.saveCompleted({ success: false });
                                    return false;
                                });
                        } else {
                            let childChanges = self.childComponentsHaveChanges();
                            if(_.getBool(args, "userInitiated") && !childChanges) self.$toast.info("No changes detected");
                            GlobalEventManager.saveCompleted({ success: true });
                            return true;
                        }
                    });
            },
            saveRate(item, changes) {
                const self = this;

                if(changes.length === 0) {
                    self.$toast.error("No changes detected.");
                    return Promise.resolve(item);
                }
                let type = self.getRoundingRowTypeName(item.roundingRowType);
                let itemName = _.get(self, `${_.camelCase(type)}Name`);

                let apiPromise = self.$api.EndorsementsApi.saveRate(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${itemName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${itemName}.`);
                        console.error(error);
                        return error;
                    });
            }
        }
    }
</script>
